import React from 'react'
import useBreakpoint from '../../../hooks/useBreakpoint'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import BlogItem, { BlogItemProps } from './BlogItem'
import * as styles from './blogItem.module.scss'

interface BlogProps {
  data: BlogItemProps[]
  variant?: BlogItemProps['variant']
  tag?: BlogItemProps['tag']
}

export default function Blog(props: BlogProps) {
  const { data = [], variant = 'sm', tag = 'h3' } = props
  const breakpoint = useBreakpoint()

  return (
    <div
      className={`${styles.blogItemWrap} ${styles[`blogItemWrap__${variant}`]}`}
    >
      {['sm', 'md'].includes(breakpoint) ? (
        <Slider
          pagination={false}
          navigation={false}
          slidesPerView="auto"
          spaceBetween={16}
          breakpoints={{
            768: {
              cssMode: false,
            },
          }}
          cssMode={true}
          data={data.map((d, dIndex) => ({
            content: (
              <BlogItem
                tag={'h2'}
                variant={variant}
                {...d}
                key={`bs-${d._id}${dIndex}`}
              />
            ),
          }))}
        />
      ) : (
        data.map((d, index) => (
          <BlogItem
            tag={'h2'}
            variant={variant}
            {...d}
            key={`bws-${d._id}${index}`}
          />
        ))
      )}
    </div>
  )
}
