import React from 'react'

interface DecoSmallIllusProps {
  className?: string
}

export default function DecoSmallIllus(props: DecoSmallIllusProps) {
  const { className = '' } = props
  return (
    <svg
      width="738"
      height="63"
      viewBox="0 0 738 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M738 0L0 53.3551V63H738V0Z"
        fill="white"
      />
    </svg>
  )
}
